@tailwind base;
@tailwind components;
@tailwind utilities;

// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@border-radius-base: 0.75rem; // mirrors rounded-lg from tailwind
@btn-border-radius-base: 0.375rem; // mirrors rounded-md from tailwind
@primary-color: #0b315f;

@font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
	BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
	sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
	'Noto Color Emoji';
