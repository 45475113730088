// TODO: remove once Safari 11.2 is no longer supported
@supports (padding-top: constant(safe-area-inset-top)) {
	html {
		--fms-safe-area-top: constant(safe-area-inset-top);
		--fms-safe-area-bottom: constant(safe-area-inset-bottom);
		--fms-safe-area-left: constant(safe-area-inset-left);
		--fms-safe-area-right: constant(safe-area-inset-right);
	}
}

@supports (padding-top: env(safe-area-inset-top)) {
	html {
		--fms-safe-area-top: env(safe-area-inset-top);
		--fms-safe-area-bottom: env(safe-area-inset-bottom);
		--fms-safe-area-left: env(safe-area-inset-left);
		--fms-safe-area-right: env(safe-area-inset-right);
	}
}

.native-safe-inset-top {
	padding-top: var(--fms-safe-area-top);
}

.fms-video-modal,
.fms-faq-modal,
.fms-welcome-modal {
	@apply w-full sm:w-full md:w-full lg:w-3/5 xl:w-2/3;
}

.fms-intro-modal {
	@apply w-full sm:w-full md:w-full lg:w-3/5;
}

.fms-audio-modal,
.fms-text-modal {
	@apply w-full sm:w-full md:w-4/5 lg:w-2/5 xl:w-1/3;
}
